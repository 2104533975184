import React, { Component } from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow } from 'mdbreact'
import Layout from '../components/layout'
import LocationsShowHide from '../components/locationsShowhide'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import get from 'lodash/get'

const Locations = class Location extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      collapseID: '',
      count: 0, 
    }}
  
    toggleCollapse = collapseID => () => {
      this.setState(prevState => ({
        collapseID: prevState.collapseID !== collapseID ? collapseID : collapseID,
      }))
    }
  
    toggleActive = collapseID => () => {
      this.setState(prevState => ({
        collapseID: prevState.collapseID !== collapseID ? collapseID : collapseID,
      }))
    }

    counter = collapseID => () => {
      this.setState(prevState => ({
        collapseID: prevState.collapseID !== collapseID ? collapseID : collapseID,
      }))
    }

  render() {
    const data = get(this.props, 'data')
    const heroImage = get(this, 'props.data.heroImage')

    return (
      <Layout>
        <SEO
          title="UNICOM Global worldwide locations"
          description="Our UNICOM Global offices are located around the world. Get in touch to discuss your requirements with us, or if you have a questsion, feel free to contact us."
          url={data.site.siteMetadata.siteUrl + this.props.location.pathname} 
          image={data.site.siteMetadata.siteUrl + heroImage.childImageSharp.gatsbyImageData.images.fallback.src}          
        />

        <Hero
          class="intro-65"
          image={heroImage.childImageSharp.gatsbyImageData}
          title="Worldwide locations"
          subtitle="Check out our office listing to find a UNICOM contact near you."
          ctatext="Get in touch"
          ctaslug="/contact/"           
          type="locations"
        />

        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <LocationsShowHide />
            </MDBRow>
          </MDBContainer>
        </section>
      </Layout>
)}}
export default Locations 

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        siteUrl
      }
    } 
    heroImage: file(name: { eq: "worldwide-locations" }) {
      childImageSharp {
        gatsbyImageData(quality: 90) 
      }
    }
  }
`